.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin-top: 10px;
  }
  
  .upload-btn-wrapper .btn {
    border: 1px solid #7664dd;
    color: #7664dd;
    background-color: white;
    padding: 8px 12px;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;
  }
  
  .upload-btn-wrapper input[type=file] {
    font-size: 0;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .ReactCrop {
    width: 100%;
  }
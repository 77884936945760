.MuiListItem-root.MuiListItem-button.Mui-selected {
  background-color: #6666ff !important;
  color: #fff;
}
.MultiSelectInput {
  border-bottom: 0.01em #6666ff solid;
}

.overflowdiv {
  overflow: hidden !important;
}
/* .paddingtop40:nth-child(2) {
  margin-top: 40px;
} */

a {
  text-decoration: none;
}

.AnnouncementTD {
  color: #000000;
  font-family: calibri;
  font-size: 16px;
  text-align: right;
  line-height: 150%;
}

.AnnouncementTD a {
  color: #000000;
}

.viewOnlineTD {
  color: #ffffff;
  font-family: calibri;
  font-size: 15px;
  text-align: left;
  line-height: 22px;
}

.viewOnlineTD a {
  color: #ffffff;
}

.menuTD {
  color: #ffffff;
  font-family: calibri;
  font-size: 15px;
  text-align: right;
  line-height: 22px;
}

.menuTD a {
  color: #ffffff;
}

.buttonTD,
.iconTextTD,
.td528Button {
  color: #ffffff;
  font-family: calibri;
  font-size: 15px;
  font-weight: lighter;
  text-align: center;
  line-height: 23px;
}

.iconTextTD {
  text-align: left;
  font-size: 13px;
  color: #c0c7d4;
}

.buttonTD a,
.td528Button a {
  color: #ffffff;
  display: block;
}

.iconTextTD a {
  color: #000000;
  font-weight: bold;
}

.headerTD {
  color: #000000;
  font-family: calibri;
  font-size: 18px;
  text-align: center;
  line-height: 25px;
}

.headerTD a {
  color: #000000;
}

.header2TD,
.iconHDTD {
  color: #cfd6e2;
  font-family: calibri;
  font-size: 17px;
  text-align: center;
  line-height: 25px;
}

.header2TD a,
.iconHDTD a {
  color: #000000;
  font-weight: bold;
}

.header3TD {
  color: #000000;
  font-family: calibri;
  font-size: 17px;
  text-align: center;
  line-height: 27px;
}

.header3TD a {
  color: #000000;
  font-weight: bold;
}

.header4TD {
  color: #000000;
  font-family: calibri;
  font-size: 18px;
  text-align: left;
  line-height: 25px;
}

.header4TD a {
  color: #000000;
}

.headerPrcTD {
  color: #000000;
  font-family: calibri;
  font-size: 40px;
  text-align: center;
}

.headerPrcTD a {
  color: #000000;
}

.iconHDTD {
  color: #ffffff;
}

.RegularTextTD,
.RegularText2TD,
.RegularText3TD,
.confLinkTD {
  color: #000000;
  font-family: calibri;
  font-size: 13px;
  text-align: left;
  line-height: 23px;
}

.RegularText3TD {
  text-align: center;
  font-size: 15px;
}

.RegularTextTD a,
.RegularText2TD a,
.RegularText3TD a {
  color: #000000;
  font-weight: bold;
}

.confLinkTD a {
  color: #67bffd;
  font-weight: bold;
  word-break: break-all;
}

.invoiceTD {
  color: #000000;
  font-family: calibri;
  font-size: 19px;
  text-align: center;
  line-height: 23px;
}

.invoiceTD a {
  color: #000000;
}

.invCap {
  color: #000000;
  font-family: calibri;
  text-align: center;
  font-size: 15px;
}

.invCap a {
  color: #000000;
}

.invReg {
  color: #000000;
  font-family: calibri;
  font-size: 13px;
  text-align: center;
}

.invReg a {
  color: #000000;
}

.invReggg {
  color: #000000;
  font-family: calibri;
  font-size: 15px;
  text-align: left;
  padding: 10px;
  line-height: 20px;
  /*border-bottom: 1px solid #000000;*/
}

.invReggg a {
  color: #000000;
}

.invRegggr {
  color: #000000;
  font-family: calibri;
  font-size: 15px;
  text-align: right;
  padding: 10px;
  line-height: 20px;
  /*border-bottom: 1px solid #000000;*/
}

.invRegggr a {
  color: #000000;
}

.invRegggv {
  color: #000000;
  font-family: calibri;
  font-size: 15px;
  text-align: right;
  padding: 10px;
  line-height: 20px;
}

.invRegggv a {
  color: #000000;
}

.invRegg {
  color: #000000;
  font-family: calibri;
  font-size: 15px;
  text-align: left;
  padding-left: 10px;
  text-transform: uppercase;
  font-weight: bold;
}

.invRegg a {
  color: #000000;
}

.invReggr {
  color: #000000;
  font-family: calibri;
  font-size: 15px;
  text-align: right;
  padding-right: 10px;
  text-transform: uppercase;
  font-weight: bold;
}

.invReggr a {
  color: #ffffff;
}

.invReggv {
  color: #000000;
  font-family: calibri;
  font-size: 15px;
  text-align: center;
  padding-left: 10px;
  text-transform: uppercase;
  font-weight: bold;
}

.invReggv a {
  color: #ffffff;
}

.invInfoA {
  color: #000000;
  font-family: calibri;
  font-size: 15px;
  text-align: right;
  line-height: 20px;
}

.invInfoA a {
  color: #000000;
  pointer-events: none;
}

.invInfoBss {
  color: #000000;
  font-family: Calibri;
  font-size: 18px;
  text-align: left;
  line-height: 23px;
  font-weight: 500;
}

.invInfoB {
  color: #000000;
  font-family: calibri;
  font-size: 15px;
  text-align: left;
  line-height: 20px;
}

.invInfoB a {
  color: #000000;
  pointer-events: none;
}

.invInfoAA {
  color: #ffffff;
  font-family: calibri;
  font-size: 15px;
  text-align: right;
  line-height: 20px;
}

.invInfoAA a {
  color: #ffffff;
  pointer-events: none;
}

.invInfoBB {
  color: #ffffff;
  font-family: calibri;
  font-size: 15px;
  text-align: left;
  line-height: 20px;
}

.invInfoBB a {
  color: #ffffff;
  pointer-events: none;
}

.invInfoBBB {
  color: #000000;
  font-family: calibri;
  font-size: 20px;
  text-align: center;
  line-height: 20px;
}

.invInfoBBB a {
  color: #000000;
  pointer-events: none;
}

td a img {
  text-decoration: none;
  border: none;
}

a {
  color: #0652b8;
}

a:active,
a:visited,
a:link {
  text-decoration: none;
}

a:hover,
a:focus {
  color: #0652b8;
  text-decoration: underline;
}

table tr.table-sub-header {
  background-color: #ddd;
}

table tr.table-sub-header td {
  padding: 5px;
}

body {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  margin: 0 !important;
  padding: 0 !important;
  min-width: 100% !important;
}

@media only screen and (max-width: 599px) {
  body {
    min-width: 100% !important;
  }

  td[class="viewOnlineTD"] {
    text-align: center !important;
  }

  table[class="table600Logo"] {
    width: 718px !important;
    border-bottom-style: solid !important;
    border-bottom-color: #e1e1e1 !important;
    border-bottom-width: 1px !important;
  }

  td[class="tdLogo"] {
    width: 440px !important;
  }

  table[class="table600Menu"] {
    width: 440px !important;
  }

  td[class="AnnouncementTD"] {
    width: 440px !important;
    text-align: center !important;
    font-size: 17px !important;
  }

  table[class="table600Menu"] td {
    height: 20px !important;
  }

  table[class="tbl6AnctText"] .menuTD {
    text-align: center !important;
    font-size: 13px !important;
    line-height: 150% !important;
  }

  table[class="tbl6AnctText"] {
    width: 440px !important;
  }

  td[class="viewOnlineTD"] {
    width: 440px !important;
  }

  td[class="menuTD"] {
    width: 440px !important;
    font-weight: bold !important;
  }

  table[class="image600"] img {
    width: 440px !important;
    height: auto !important;
  }

  table[class="AnncTable"] {
    width: 100% !important;
    border: none !important;
  }

  table[class="table280d"] {
    width: 440px !important;
    border-radius: 0 0 0 0 !important;
  }

  td[class="LMrg"] {
    height: 8px !important;
  }

  td[class="LMrg2"] {
    height: 6px !important;
  }

  td[class="LMrg3"] {
    height: 10px !important;
  }

  table[class="tblRgBrdr"] {
    border-right: none !important;
  }

  td[class="td147"] {
    width: 215px !important;
  }

  table[class="table147"] {
    width: 215px !important;
  }

  table[class="table147tblp"] {
    width: 175px !important;
  }

  td[class="mrgnHrzntlMdl"] {
    width: 10px !important;
  }

  td[class="mvd"] {
    height: 30px !important;
    width: 440px !important;
  }

  table[class="centerize"] {
    margin: 0 auto 0 auto !important;
  }

  table[class="tblBtnCntr2"] {
    width: 398px !important;
    margin: 0 auto 0 auto !important;
  }

  td[class="table28Sqr"] img {
    width: 440px !important;
    height: auto !important;
    margin: 0 auto 0 auto !important;
    border-radius: 4px 4px 0 0 !important;
  }

  td[class="tbl28Rctngl"] img {
    width: 215px !important;
    height: auto !important;
    margin: 0 auto 0 auto !important;
  }

  td[class="headerTD"] {
    text-align: center !important;
  }

  td[class="header4TD"] {
    text-align: center !important;
  }

  td[class="headerPrcTD"] {
    font-size: 25px !important;
  }

  td[class="RegularTextTD"] {
    font-size: 13px !important;
  }

  td[class="RegularText2TD"] {
    height: 0 !important;
    font-size: 13px !important;
  }

  td[class="RegularText3TD"] {
    font-size: 13px !important;
  }

  td[class="mailingOptionsTD"] {
    text-align: center !important;
  }

  td[class="companyAddressTD"] {
    text-align: center !important;
  }

  td[class="esFrMb"] {
    width: 0 !important;
    height: 0 !important;
    display: none !important;
  }

  table[class="table280brdr"] {
    width: 440px !important;
  }

  table[class="table708"] {
    width: 438px !important;
  }

  table[class="table518b"] {
    width: 398px !important;
  }

  table[class="table518"] {
    width: 398px !important;
  }

  table[class="table518c"] {
    width: 195px !important;
  }

  table[class="table518c2"] {
    width: 195px !important;
  }

  td[class="imgAltTxticTD"] img {
    width: 398px !important;
    height: auto !important;
    margin: 0 auto 17px auto !important;
  }

  td[class="iconPdngErase"] {
    width: 0 !important;
    display: none !important;
  }

  td[class="table708"] {
    width: 438px !important;
  }

  td[class="invReg"] {
    width: 133px !important;
    font-size: 14px !important;
    text-align: center !important;
    font-weight: lighter !important;
  }

  td[class="invInfoA"] {
    text-align: right !important;
    width: 195px !important;
  }

  td[class="invInfoB"] {
    text-align: left !important;
    width: 195px !important;
  }

  td[class="invoiceTD"] {
    width: 390px !important;
    font-weight: bold;
  }

  td[class="td528Button"] {
    width: 358px !important;
  }

  table[class="table528Button"] {
    width: 358px !important;
  }

  table[class="table528Social"] {
    width: 398px !important;
  }

  table[class="table314"] {
    width: 177px !important;
  }
}

@media only screen and (max-width: 479px) {
  body {
    min-width: 100% !important;
  }

  td[class="viewOnlineTD"] {
    text-align: center !important;
  }

  table[class="table600Logo"] {
    width: 300px !important;
    border-bottom-style: solid !important;
    border-bottom-color: #e1e1e1 !important;
    border-bottom-width: 1px !important;
  }

  td[class="tdLogo"] {
    width: 300px !important;
  }

  table[class="table600Menu"] {
    width: 300px !important;
  }

  td[class="AnnouncementTD"] {
    width: 300px !important;
    text-align: center !important;
    font-size: 17px !important;
  }

  table[class="table600Menu"] td {
    height: 20px !important;
  }

  table[class="tbl6AnctText"] .menuTD {
    text-align: center !important;
    font-size: 15px !important;
    line-height: 150% !important;
  }

  table[class="tbl6AnctText"] {
    width: 300px !important;
  }

  td[class="viewOnlineTD"] {
    width: 300px !important;
  }

  td[class="menuTD"] {
    width: 300px !important;
    font-weight: bold !important;
  }

  table[class="image600"] img {
    width: 300px !important;
    height: auto !important;
  }

  table[class="table708"] {
    width: 298px !important;
  }

  td[class="table708"] {
    width: 298px !important;
  }

  table[class="table518"] {
    width: 260px !important;
  }

  table[class="table518b"] {
    width: 268px !important;
  }

  table[class="table518c"] {
    width: 268px !important;
  }

  table[class="table518c2"] {
    width: 268px !important;
    margin: 20px 0 0 0 !important;
  }

  td[class="imgAltTxticTD"] img {
    width: 268px !important;
    height: auto !important;
    margin: -4px auto 15px auto !important;
  }

  table[class="table280Button"] {
    width: 264px !important;
  }

  table[class="centerize"] {
    margin: 0 auto 0 auto !important;
  }

  table[class="tblBtnCntr2"] {
    width: 264px !important;
    margin: 0 auto 0 auto !important;
  }

  table[class="AnncTable"] {
    width: 100% !important;
    border: none !important;
  }

  table[class="table280d"] {
    width: 300px !important;
    border-radius: 0 0 0 0 !important;
  }

  td[class="LMrg"] {
    height: 8px !important;
  }

  td[class="LMrg2"] {
    height: 6px !important;
  }

  td[class="LMrg3"] {
    height: 10px !important;
  }

  td[class="wz"] {
    width: 15px !important;
  }

  table[class="tblRgBrdr"] {
    border-right: none !important;
  }

  td[class="td147"] {
    width: 147px !important;
  }

  table[class="table147"] {
    width: 147px !important;
  }

  table[class="table147tblp"] {
    width: 117px !important;
  }

  td[class="mrgnHrzntlMdl"] {
    width: 6px !important;
  }

  td[class="mvd"] {
    height: 30px !important;
    width: 300px !important;
  }

  td[class="iconPdngErase"] {
    width: 0 !important;
    display: none !important;
  }

  td[class="table28Sqr"] img {
    width: 300px !important;
    height: auto !important;
    margin: 0 auto 0 auto !important;
    border-radius: 4px 4px 0 0 !important;
  }

  td[class="tbl28Rctngl"] img {
    width: 147px !important;
    height: auto !important;
    margin: 0 auto 0 auto !important;
  }

  td[class="headerTD"] {
    font-size: 16px !important;
    font-weight: bold !important;
    text-align: center !important;
  }

  td[class="header4TD"] {
    font-size: 16px !important;
    font-weight: bold !important;
    text-align: center !important;
  }

  td[class="iconHDTD"] {
    font-size: 16px !important;
    text-align: center !important;
  }

  td[class="headerPrcTD"] {
    font-size: 18px !important;
  }

  td[class="RegularTextTD"] {
    font-size: 13px !important;
    text-align: left !important;
  }

  td[class="RegularText2TD"] {
    height: 0 !important;
    font-size: 13px !important;
  }

  td[class="RegularText3TD"] {
    font-size: 13px !important;
  }

  td[class="mailingOptionsTD"] {
    text-align: center !important;
  }

  td[class="companyAddressTD"] {
    text-align: center !important;
  }

  td[class="esFrMb"] {
    width: 0 !important;
    height: 0 !important;
    display: none !important;
  }

  table[class="table280brdr"] {
    width: 300px !important;
  }

  td[class="invReg"] {
    width: 89px !important;
    font-size: 13px !important;
    text-align: center !important;
  }

  td[class="invInfoA"] {
    text-align: center !important;
    width: 268px !important;
  }

  td[class="invInfoB"] {
    text-align: center !important;
    width: 268px !important;
  }

  td[class="invoiceTD"] {
    width: 314px !important;
  }

  td[class="buttonVrt"] {
    height: 16px !important;
  }

  td[class="buttonVrt2"] {
    height: 12px !important;
  }

  td[class="buttonVrt3"] {
    height: 10px !important;
  }

  td[class="td528Button"] {
    width: 238px !important;
  }

  table[class="table528Button"] {
    width: 238px !important;
  }

  table[class="table528Social"] {
    width: 266px !important;
  }

  table[class="table314"] {
    width: 117px !important;
  }

  td[class="termsCon2TD"] {
    text-align: center !important;
  }

  td[class="termsConTD"] {
    text-align: center !important;
  }
}
